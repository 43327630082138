import { GatsbyBrowser } from 'gatsby';

import { AuthProvider } from 'contexts/AuthProvider';
import { ThemeProvider } from 'contexts/ThemeProvider';
import { FaqSearchProvider } from 'contexts/FaqSearchProvider';
import { ModalProvider } from 'contexts/ModalProvider';
import AnnouncementBarProvider from 'contexts/AnnouncementBarContext';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const onInitialClientRender = () => {
  if (typeof window !== 'undefined' && !window.MktoForms2) {
    const script = document.createElement('script');
    script.src = '//info.snowflake.com/js/forms2/js/forms2.min.js';
    script.async = true;
    document.body.appendChild(script);
  }
};

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => (
  <ThemeProvider>
    <AuthProvider>
      <AnnouncementBarProvider>
        <ModalProvider>
          <FaqSearchProvider>{element}</FaqSearchProvider>
        </ModalProvider>
      </AnnouncementBarProvider>
    </AuthProvider>
  </ThemeProvider>
);
