exports.components = {
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-certification-detail-tsx": () => import("./../../../src/templates/certification-detail.tsx" /* webpackChunkName: "component---src-templates-certification-detail-tsx" */),
  "component---src-templates-course-detail-tsx": () => import("./../../../src/templates/course-detail.tsx" /* webpackChunkName: "component---src-templates-course-detail-tsx" */),
  "component---src-templates-faq-tsx": () => import("./../../../src/templates/faq.tsx" /* webpackChunkName: "component---src-templates-faq-tsx" */),
  "component---src-templates-learning-track-tsx": () => import("./../../../src/templates/learning-track.tsx" /* webpackChunkName: "component---src-templates-learning-track-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-persona-tsx": () => import("./../../../src/templates/persona.tsx" /* webpackChunkName: "component---src-templates-persona-tsx" */)
}

